module.exports = new Promise((resolve, reject) => {
      const remoteScope = 'vendorMfe'
      const remoteURLs = JSON.parse('{"vendorMfe":{"local":"http://127.0.0.1:8090/remoteEntry.js","dev":"https://gw-dev-vendor-mfe-web-a3cea9h2b4gch9eq.canadacentral-01.azurewebsites.net/remoteEntry.js","qa":"https://gw-qa-vendor-mfe-web-gjgched6hubff6c7.canadacentral-01.azurewebsites.net/remoteEntry.js","uat":"https://gw-uat-vendor-mfe-web-efc7dyargsa9bcdr.canadacentral-01.azurewebsites.net/remoteEntry.js","prod":"https://gw-prod-vendor-mfe-web-bxd9gxevadgxd3bf.canadacentral-01.azurewebsites.net/remoteEntry.js"}}')
      const isDevelopmentEnv = false
      const remoteEnv = ''
      
      const getRemoteURL = () => {
        if (window.location.hostname.includes('local') || window.location.hostname.includes('127.0.0.1'))
          return remoteURLs[remoteScope].local

        if (window.location.hostname.includes('-qa')) {
          return remoteURLs[remoteScope].qa
        }
        
        if (window.location.hostname.includes('-dev')) {
          return remoteURLs[remoteScope].dev
        }
        
        if (window.location.hostname.includes('-uat')) {
          return remoteURLs[remoteScope].uat
        }
        
        return remoteURLs[remoteScope].prod
      }
  
      
      const initRemoteEntryScript = (url, onError = null) => {
        const script = document.createElement('script')
        script.src = url
        script.onload = () => {
          // the injected script has loaded and is available on window
          // we can now resolve this Promise
          const proxy = {
            get: (request) => window['vendorMfe'].get(request),
            init: (...arg) => {
              try {
                return window['vendorMfe'].init(...arg)
              } catch(e) {
                console.log('remote container already initialized')
              }
            }
          }
          const parentScope = remoteURLs[remoteScope].parentScope
          
          if(!parentScope || window[parentScope]){
            resolve(proxy)
          } else {
            initRemoteEntryScript(url, onError)
          }
        }
        script.onerror = () => {
          if(onError){
            onError()
          } else {
            reject(new Error(`[vendorMfe] error loading remote`))
          }
        }
        // inject this script with the src set to the versioned remoteEntry.js
        document.head.appendChild(script);
      }
      
      const remoteUrl = remoteEnv ? remoteURLs[remoteScope][remoteEnv] : getRemoteURL()
      const remoteUrlFallback = remoteURLs[remoteScope].dev
      
      if (!window[remoteScope]) {
        if(isDevelopmentEnv && !remoteEnv){
          initRemoteEntryScript(remoteUrl, () => initRemoteEntryScript(remoteUrlFallback))
        } else {
          initRemoteEntryScript(remoteUrl)
        }
      } else {
        resolve(window[remoteScope])
      }
    })
    ;